//@ts-ignore
import Nexus from "nexusui";
import * as React from "react";
import { render } from "react-dom";
import {
  Button,
  Toggle,
  Dial,
  Number,
  Position,
  Slider,
  Envelope,
  Multislider,
  RadioButton,
  Select,
  Sequencer,
  TextButton,
  Tilt,
  Pan,
  Pan2D
} from "../src/";
//const dialColors = Array.from({ length: 16 }, (d, i) => i);
const dialColors = ['#a70','#f00','#0f0','#00f','#aaa','#f00','#0f0','#00f','#370','#ff0','#fff','#a0f','#a70','#270','#fff','#a0f','#370','#ff0'];
const dialNames = ['time','red','green','blue','alpha','red*','green*','blue*','sobel','badtv','steps','ratio','zoom','audio*','expo','pixel8','trixel','chroma'];

const emitToSocket = (value , index) => {
  /*
  CONNECTING	0	
  OPEN	1	
  CLOSING	2	
  CLOSED	3	
  */
 if (window.socket && ws.readyState == 1) {
   window.socket.send('{"params" :[{"name" : ' + index + ',"value" :' + value + '}]}');
   console.log(`emitToSocket readyState ${ws.readyState}, val: ${value}, idx: ${index} `);

  } 

};

const listenToSocket = onKeyDown => {
  console.log('listenToSocket');
  document.addEventListener("keydown", onKeyDown);
  return () => {
    document.removeEventListener("keydown", onKeyDown);
  };
};
function TitleAndChildren({ children, title }) {
  return (
    <div style={{ margin: 10 }}>
      <h2 className={"subtitle"} style={{ textAlign: "center" }}>{title}</h2>
      {children}
    </div>
  );
}

const mouseChange = (pos) => {
  console.log(`mouseChange ${pos.x} ${pos.y} `);
  if (window.socket && ws.readyState == 1) {
    window.socket.send('{"params" :[{"name" : 42,"value" :' + pos.x + '},{"name" : 43,"value" :' + pos.y + '}]}');
   } 
}
const xFadeChange = (val) => {
  console.log(`mouseChange ${val} `);
  emitToSocket(val, 18);
}
const mixChange = (valuesArray) => {
  console.log(`mixChange ${JSON.stringify(valuesArray)} } `);// 31 to 39
  valuesArray.map( (item, index) => {
    
    console.log(`mixChange ${item} ${index} `);
    emitToSocket(item, 31 + index);
  });
}
function Core() {
  return (
    <section className="section">
      <div className="container">
        <h2 className="title">Core</h2>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <TitleAndChildren title="xFade">
            <Slider size={[120, 20]} onChange={xFadeChange} />
          </TitleAndChildren>
          <TitleAndChildren title="Mouse">
            <Position onChange={mouseChange} />
          </TitleAndChildren>
          <TitleAndChildren title={"Toggle"}>
            <Toggle size={[100, 30]} state={false} />
          </TitleAndChildren>
          <TitleAndChildren title={"Button"}>
            <Button size={[100, 30]} mode={"button"} state={false} />
          </TitleAndChildren>
          <TitleAndChildren title={"Button"}>
            <Number step={1} min={0} max={10} value={4} />
          </TitleAndChildren>
        </div>
      </div>
    </section>
  );
}

function General() {
  const sequencerRef = React.useRef<null | Nexus.Sequencer>(null);
  return (
    <section className="section">
      <div className="container">
        <h2 className="title">General</h2>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <TitleAndChildren title="Envelope">
            <Envelope points={[{ x: 0.1, y: 0.4 }]} />
          </TitleAndChildren>
          <TitleAndChildren title="Mix">
            <Multislider
              size={[200, 100]}
              numberOfSliders={7}
              min={0}
              max={1}
              step={0}
              candycane={3}
              values={[0.9, 0.8, 0.7, 0.6, 0.5, 0.4, 0.3, 0.2, 0.1]}
              smoothing={0}
              mode={"bar"}
              onChange={mixChange}
            />
          </TitleAndChildren>
          
          <TitleAndChildren title="RadioButton">
            <RadioButton
              size={[180, 25]}
              numberOfButtons={4}
              onChange={active => {
                if (window.socket) window.socket.send('{"params" :[{"name" : ' + '0' + ',"value" :' + active + '}]}');

                console.warn({ active });
              }}
            />
          </TitleAndChildren>
          <TitleAndChildren title="Select">
            <Select options={["default", "options"]} value={"options"} />
          </TitleAndChildren>
          <TitleAndChildren title="Sequencer">
            <Sequencer
              rows={5}
              columns={10}
              size={[400, 200]}
              onStep={console.warn}
              onReady={sequencer => (sequencerRef.current = sequencer)}
            />
            <div>
              <button
                onClick={() => {
                  sequencerRef.current.start(500);
                }}
              >
                Play Sequencer
              </button>
              <button
                onClick={() => {
                  sequencerRef.current.stop(500);
                }}
              >
                Stop Sequencer
              </button>
            </div>
          </TitleAndChildren>
          <TitleAndChildren title={"TextButton"}>
            <TextButton text={"Hi"} state={false} />
            <TextButton text={"Hi"} alternateText={"Bye"} state={true} />
          </TitleAndChildren>
        </div>
      </div>
    </section>
  );
}

function Mobile() {
  return (
    <section className="section">
      <div className="container">
        <h2 className="title">Mobile</h2>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <TitleAndChildren title={"Tilt"}>
            <Tilt
              size={[200, 100]}
              active={true}
              onChange={tilt => {
                console.log("Tilt changed", tilt);
              }}
            />
          </TitleAndChildren>
        </div>
      </div>
    </section>
  );
}

function Spatialization() {
  return (
    <section className="section">
      <div className="container">
        <h2 className="title">Spatialization</h2>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <TitleAndChildren title={"Pan"}>
            <Pan value={0} />
          </TitleAndChildren>
          <TitleAndChildren title={"Pan2D"}>
            <Pan2D onChange={console.warn} />
          </TitleAndChildren>
        </div>
      </div>
    </section>
  );
}

function initWs() {
  window.ws = (function (uri) {
    console.log('ws init')
    ws = new WebSocket(uri);
    ws.onmessage = function(evt) {
      console.log('ws rsg rcvd')
        var messageData = JSON.parse(evt.data);
        var customEvt = new CustomEvent(messageData.event);
        customEvt.data = messageData.message;
        ws.dispatchEvent(customEvt);
    };
    this.emit = function(evt, data) {
        ws.send(JSON.stringify({event:evt, message: data}));
    };
    this.send = function(data) {
        ws.send(data);
    };
    this.on = function(evt, func) {
        ws.addEventListener(evt, func);
    };
    ws.onerror = function(e) {console.log('error: ' + JSON.stringify(e))};
    ws.onopen = function(evt) {console.log('Socket opened')};
    ws.onclose = function(evt) {console.log('Socket closed')};
  });
  
  window.socket = new ws('ws://127.0.0.1:8088');
  //window.socket = new ws('ws://192.168.43.150:8088');
}
initWs();
function App() {
  //initWs();
  let dialRefs = React.useRef([] as any[]);
  let dials = [] as React.ReactElement[]; 
  React.useEffect(() => {
    const stopListening = listenToSocket(() => {
      console.log('stopListening');
      if (dialRefs.current.length > 0) {
        dialRefs.current[0].value = Math.random() + 0.5
      }

    });
    return stopListening;
  }, []);
  for (let i = 0; i < 18; i += 1) {
    dials.push(
      <TitleAndChildren key={i} title={dialNames[i]}>
      <Dial
        interaction={"radial"}
        key={i}
        onReady={dialRef => {
          dialRefs.current.push(dialRef);
          dialRef.colorize("accent", dialColors[i]);
        }}
        onChange={value => {
          emitToSocket(value, i);
        }}
        value={1}
        min={0}
        max={1}
      />
      </TitleAndChildren>
    );
  }

  return (   
    <>
      <section className="section">
        <div className="container">
          <h2 className="title">Colors</h2>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {dials}
          </div>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
          <TitleAndChildren title="Fbo A">
            <RadioButton
              size={[180, 25]}
              numberOfButtons={12}
              onChange={active => {
                if (window.socket && ws.readyState == 1) window.socket.send('{"cmd" :[{"type" : 0,"warp" : 0,"fbo" :' + active + ',"slot" :0}]}');
                console.warn({ active });
              }}
            />
          </TitleAndChildren>
          <TitleAndChildren title="Fbo B">
            <RadioButton
              size={[180, 25]}
              numberOfButtons={12}
              onChange={active => {
                if (window.socket && ws.readyState == 1) window.socket.send('{"cmd" :[{"type" : 0,"warp" : 0,"fbo" :' + active + ',"slot" :1}]}');
                console.warn({ active });
              }}
            />
          </TitleAndChildren>
          </div>
        </div>
      </section> 
      <Core />
      <General />
      <Mobile />
      <Spatialization />
      </>
  );
}
/*<React.Fragment> <Core />
      <General />
      <Mobile />
      <Spatialization />
      </React.Fragment> */
render(<App />, document.getElementById("app"));
